.contact_form {
	.small-contact {
		background-color:$nav-blue;
		.contact-inner {
			padding-top: 20px;
			padding-bottom: 20px;

		}



		input, textarea {
			font-size:$font-xsmall;
			border:1px solid $light-blue;
			border-radius:5px;

		}
		textarea:focus, input:focus {
			outline: none !important;
			border:1px solid $light-blue;
			box-shadow: 0 2px 4px rgba(0,0,0,0.50);
			inset: 0 1px 3px rgba(0,0,0,0.50);
		}
		textarea {
			min-height:150px;
			margin-bottom: 25px;
		}
		@import "resources/assets/sass/_partials/buttons/button";
		@import "resources/assets/sass/_partials/buttons/secondary";


		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: $black2;
		}
		::-moz-placeholder { /* Firefox 19+ */
			color: $black2;
		}
		:-ms-input-placeholder { /* IE 10+ */
			color: $black2;
		}
		:-moz-placeholder { /* Firefox 18- */
			color: $black2;
		}

	}
	@media (max-width:$screen-sm-min) {
		margin-left: -15px;
		margin-right: -15px;
		.small-contact {
			padding-left: 15px;
			padding-right: 15px;
			textarea {
				min-height:140px;
			}
		}
	}

}
