.background-bar {
	background-color: $light-grey;
}

.pages_rows {

	.background_regular:nth-child(even) {
		background-color: $off-white;
	}

	.background_blue {
		background-color: $nav-blue;
		color: $white !important;
		h1, h2 {
			color: $white !important;
		}
	}

	.page_row {
		
		&.Left {
			text-align: left;
			@media(min-width:$screen-sm-min){
				& ul {
					margin-left: 5%;

				}
			}
		}
		&.Centered {
			text-align: center;
			@media(min-width:$screen-sm-min){
				& ul {
					margin-left: 20%;

				}
			}

		}
		
		h2 {
			margin-top: 1em;
			margin-bottom: 0.8em;
			margin-left: 0;
			font-weight: $weight-bold;
			text-transform: capitalize;
			
		}
		h3 {
			font-weight: $weight-bold;
		}

		p {
			font-size: $font-small;
			font-weight: $weight-regular;
		}
		a {
			color: $nav-blue;
		}

		.title-sub-text {
			font-weight:$weight-bold;
			margin:0.8em;
			font-size: $font-size-large;
		}

		.button {
			border: 2px solid $black2;
			font-size: $font-size-large;
			text-transform: lowercase;
			min-width:200px;
			color: $black2;
			padding: 3px 12px;

			&.btn-secondary {
				min-width:220px;

			}

			&:hover {
				background-color: $black2;
				color: $white;
				border: 2px solid $primary;
			}

		}

		@media(max-width:$screen-sm-max){
			.button_centered .button_wrap {
				margin-top: 10px;
				margin-bottom: 20px;
			}
		}
		.button_wrap {
			margin-bottom: 30px;
		}

		@import "resources/assets/sass/_partials/buttons/button";
		@import "resources/assets/sass/_partials/buttons/secondary";
		@import "resources/assets/sass/_partials/pages/bullets";

		@media(max-width:$screen-xs-max){
			h3 {
				font-size: $font-small;
			}
			h2 {
				font-size: $font-medium;
			}
			p {
				font-size: $font-smaller;
			}
		}


	}

}

.mobile-title {

	h2 {
		margin: 0.7em;
	}


}

@media(max-width: $screen-xs-max){

	.pages_rows {
		.page_row {

			.button {
				font-size: $font-size-large;
			}
		}
	}

}

@media(max-width: $screen-sm-max){
	.pages_rows {
		.page_row {

			.button {
				background-color: $black2;
				color: $white;
				border: 2px solid $primary;
			}
		}
	}
}

@media(max-width: $screen-sm-min){
	.pages_rows {

		.page_row {
			&  img,  p  img {
				max-width:100%;
			}


			.button_wrap {
				margin-bottom: 20px;
			}


		}
	}


}
