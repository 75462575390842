#header {
	background: $black2;
	.header-inner {
		height: 50px;
		width:100%;
		display:block;
		a {
			text-decoration: none;
		}

		.logo {

			display: flex;
			justify-content: left;
			align-items: center;
			z-index:10;
			height:50px;
			padding:10px;
			.logo-mobile {
				display:none;
			}
			font-size: $font-size-xlarge;
			text-transform: lowercase;
			font-weight: $weight-light;
			a {
				&:hover  >.white {
					color: $primary;
				}
			}
			.blue {
				color: $light-blue;
			}
			.white {
				color: $white;

			}


		}
		.header-link {
			display: flex;
			justify-content: right;
			align-items: center;
			height: 50px;
			width:100%;
			@media(max-width:$screen-sm-max) {
				justify-content: center;
				}
			p {
				display: none;

				&.active {
					display: block!important;
				}
			}
			a {
				text-decoration: none;
			}
		}
	}


	.phone {
		font-size: $font-medium;
		font-weight: $weight-bold;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-align: right;
		z-index:10;
		height:100%;
		a {
			color: $white;
			text-decoration: none

		}
		&:hover a {
			color: $primary;
		}
		p {
			margin:0;
		}
	}
	.mobile-btn {
		span {
			border-radius: 6px;
			padding: 5px 7px;
			font-size: $font-size-xlarge;
		}



	}


	.mobile {
		display: none;
		a:hover {
			color: $nav-blue;
		}

		img {
			width: 25px;
		}

		@media(max-width:$screen-sm-max) {
			display: inline-block;
		}
	}
	@media(max-width:$screen-sm-max) {
		height:50px;
		.header-inner {
			height: 50px;
			display: block;
			padding-left: 10px;
			.logo {
				.logo-desktop {
					display: none;
				}
			}
			.btn {
				padding-right:0;
				padding-left:0;
			}
			.logo-mobile {
				display: inline-block;
				font-size:$font-size-large;
				text-transform: lowercase;
				font-weight: 300;
				.blue {
					color: $light-blue;
				}
				.white {
					color: $white;
				}

			}


			.header-link {
				height: auto;
			}

		}
	}
}

#header-shadow {
	background-color: $orange;
	height:1px;
}

