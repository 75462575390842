

.spacer {
	padding-top:$spacer-height;
	background-color: $backgound-primary;

	&.active-sp {
		padding-top:170px;
		transition: padding-top .5s ease-in;
		@media(max-width:$screen-xs-max){
			padding-top:114px;
		}
		@media(max-width:$screen-sm-max){
			padding-top:120px;
		}

	}
	@media(max-width:$screen-sm-max){
		& {
			padding-top:$spacer-mobile-height;
		}
	}
	@media (min-width:$screen-lg-min) {
		& {
			padding-top:$spacer-height;
		}
	}


}
