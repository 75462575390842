#footer {
    background: $backgound-secondary;
    color: $white;
    padding-top: 36px;
    font-size:$font-small;


    h5 {
        font-size: $font-size-large;
        color: $white;
        text-transform: capitalize;
        padding-bottom: 20px;
    }

    p.business-name {
        text-transform:uppercase;
    }

    .fa {
        margin-right: 10px;
    }

    a {
        text-decoration: none;
        color:$white;
        &:hover {
            color: $primary;
        }
    }
    .contact-img {
        display: inline-block;
        margin-right: 10px;
    }
    li {
        padding-bottom: 30px;
    }

    @media(max-width:$screen-md-max){
        span.contact {
            font-size: 15px;
        }
    }
    @media(max-width:$screen-sm-max){
        text-align: center;
        padding-top: 0;
        span.contact {
            font-size: $font-small;
        }
        li {
            padding-bottom: 20px;

        }

        h5 {
            padding: 9px;
            font-size: $font-medium;
        }
        .contact-img {
            margin-right: 0;
            margin-bottom: 5px;
        }

    }

}
