.button-secondary {
	border: 2px solid $white;
	font-size: $font-size-large;
	color:$white;
	min-width:200px;
	background-color: transparent;

	&:hover {
		background-color: $black2;
		border: 2px solid $light-blue;
		color: $white;
	}
}

