#header_image_link {
	background-color: $off-white;
	@media (min-width:$screen-md-min) {
		.row-image-link {
			margin-top:-100px;
			margin-bottom: 1em;
		}

		.header-image-link {
			z-index: 89;
			position:relative;

		}
		.col-md-3 {
			width: 33%;
		}


	}
	@media (max-width:$screen-sm-max) {
		.no-padding {
			padding-right:0;
		}
		.no-margin {
			margin-right: 0px;
		}



	}
	@media (max-width:$screen-xs-max) {
		.image-link {
		}
		.offset0 {
			margin-left: 0;
		}
	}
}
