.jump_nav {
	padding:0.8em;
	.fa {
		font-size: $font-size-max;
		color:$secondary;
		margin:5px;
		&:hover {
			color:$primary !important;
		}

		&.fa-chevron-up {
			padding-right:10px;
		}

		&.fa-chevron-down {
			padding-left:10px;
		}
	}
}
