.blog {
	h2 {
		text-transform: none;
		font-weight: $weight-regular;
	}
	.subtitle {
		font-size: $font-size-large;
		font-weight: $weight-light;
	}
	.button {
		border: 2px solid $black2;
		font-size: $font-size-large;
		text-transform: lowercase;
		min-width:200px;
		color: $black2;
		padding: 0;


		&.btn-secondary {
			min-width:220px;

		}

		&:hover {
			background-color: $black2;
			color: $white;
			border: 2px solid $primary;
		}

	}
	@media(max-width: $screen-sm-max){
		h2 {
			font-size: $font-medium;
		}
		.subtitle {
			font-size: $font-smaller;
			margin-bottom: 20px;
		}
		.button {
			background-color: $black2;
			color: $white;
			border: 2px solid $primary;
		}
	}
}
.social-space {
	display: inline-block;
	width: 10px;
}
.fa-google-plus-square {
	color:$google-plus;
}
.fa-facebook-official {
	color: $facebook;
}
.fa-linkedin-square {
	color: $linkedin;
}
.fa-twitter-square {
	color: $twitter;
}
