.copyright {
		color: $white;
		position:absolute;
		left:0;
		height:67px;
		width:100%;
		font-size:$font-small;
		text-transform: capitalize;
		background-color: $black2;
	}
