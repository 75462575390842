.page-row-image-center {
	@import "resources/assets/sass/_partials/pages/bullets";

	.page_row_inner {
		img {
			display: inline-block;
			@media(max-width: $screen-xs-max){
				margin-bottom: 20px;
			}

		}
	}
}
