.page-row-carousel {
    @media(min-width:$screen-sm-min){
        .button_wrap {
            text-align: left;
        }
    }
    @media(max-width:$screen-xs-max){
        .button_wrap {
            text-align: center;
        }
    }

}
