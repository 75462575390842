@import "resources/assets/sass/_variables";
@import "node_modules/font-awesome/scss/font-awesome";
@import "resources/assets/css/lightbox";
@import "resources/assets/sass/bootstrap_variables";
@import "resources/assets/sass/bootstrap";

// Header Section
@import "resources/assets/sass/_partials/header/nav";
@import "resources/assets/sass/_partials/header/spacer";
@import "resources/assets/sass/_partials/header/header";
@import "resources/assets/sass/_partials/header/banner";
@import "resources/assets/sass/_partials/header/header_image_link";
// @import "resources/assets/sass/_partials/header/nav_link";
@import "resources/assets/sass/_partials/header/page_header";


// // Footer Section
@import "resources/assets/sass/_partials/footer/footer";
@import "resources/assets/sass/_partials/footer/copyright";

// //Components
@import "resources/assets/sass/_partials/components/editable";
// @import "resources/assets/sass/_partials/components/filter_bar";
@import "resources/assets/sass/_partials/components/jump_nav";
@import "resources/assets/sass/_partials/components/contact_form";
// @import "resources/assets/sass/_partials/components/breadcrumbs";
// //Shortcodes
// @import "resources/assets/sass/_partials/shortcodes/video";
// @import "resources/assets/sass/_partials/shortcodes/image_reel";
@import "resources/assets/sass/_partials/shortcodes/card_carousel";
@import "resources/assets/sass/_partials/shortcodes/image_link_list";
@import "resources/assets/sass/_partials/shortcodes/image_link_standard";
@import "resources/assets/sass/_partials/shortcodes/staff";
@import "resources/assets/sass/_partials/shortcodes/job_list";
@import "resources/assets/sass/_partials/shortcodes/contact";
// //Media & Assets
@import "resources/assets/sass/_partials/media_assets/gallery_cards";
@import "resources/assets/sass/_partials/media_assets/gallery_images";
// //Blog
@import "resources/assets/sass/_partials/blog/posts";
@import "resources/assets/sass/_partials/blog/blog";
@import "resources/assets/sass/_partials/blog/post_grid";
// @import "resources/assets/sass/_partials/blog/post_category_links";

// //Pages
@import "resources/assets/sass/_partials/pages/page";
@import "resources/assets/sass/_partials/pages/page_row";
@import "resources/assets/sass/_partials/pages/image_left";
@import "resources/assets/sass/_partials/pages/image_center";
@import "resources/assets/sass/_partials/pages/carousel";

// Singletons

h2 {
	text-transform: uppercase;
	font-size:$font-size-xlarge;
	font-weight: $weight-light;
}


body {
	font-family: $headings-font-family;
	font-weight:100;
}

.map {
	height:340px;

}

@media(max-width: $screen-xs-max){
	h2 {
		font-size:$font-smaller;
	}
}



@media(max-width:$screen-xs-max){
}

@media (min-width:$screen-sm-min) {
}

@media (min-width:$screen-md-min) {
}

@media (min-width:$screen-lg-min) {
}
.itemprop-none {
	display:none;
}
.wysihtml5-editor {


	h1 {
		font-family: $headings-font-family;
		font-size:$font-size-xxlarge;
		margin-top: 25px;
		margin-bottom: 12.5px;
		font-weight: 500;
	}
	h2 {
		font-family: $headings-font-family;
		margin-top: 1em;
		margin-bottom: 0.8em;
		margin-left: 0;
		text-transform: capitalize;
		font-weight: $weight-bold;
		font-size:$font-size-xlarge;
	}
	h3 {
		font-family: $headings-font-family;
		font-weight: $weight-bold;
		font-size: $font-size-large;
	}

	p {
		font-family: $headings-font-family;
		font-size: $font-small;
		font-weight: $weight-regular;
	}
}
