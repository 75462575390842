
&.bullet_sun {

    margin-top: 1em;

    ul {
        @media(min-width:$screen-sm-min){
        max-width: 60%;
    }
        margin-bottom:6px;
        li {
            list-style: none;
            font-size: 16px;
            font-weight: $weight-bold;
            text-align: left;
            margin-left:5px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f185";
                font-family: FontAwesome;
                color: $secondary;
                font-size: 24px;
                margin-left: -33px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}

&.bullet_tick {

    ul {
        @media(min-width:$screen-sm-min){
        max-width: 60%;
    }
        margin-top: 20px;
        

        li {
            list-style: none;
            margin-bottom: 20px;
            font-size:16px;


            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f05d";
                font-family: FontAwesome;
                color: #7ACF1A;
                font-size: 24px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}

&.bullet_star {

    ul {
        @media(min-width:$screen-sm-min){
        max-width: 60%;
    }
        margin-top: 20px;
        

        li {
            list-style: none;
            margin-bottom: 20px;
            font-size: 16px;


            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f006";
                font-family: FontAwesome;
                color: #FF8000;
                font-size: 24px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
            }
        }
    }
}
&.bullet_tree {

    ul {
        @media(min-width:$screen-sm-min){
        max-width: 60%;
    }
        margin-top: 20px;
        

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: normal;
            font-size: 16px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f1bb ";
                font-family: FontAwesome;
                color: #7ACF1A;
                font-size: 24px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}
&.bullet_snowflake {

    ul {
        @media(min-width:$screen-sm-min){
        max-width: 60%;
    }
        margin-top: 20px;
        

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: normal;
            font-size: 16px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f2dc";
                font-family: FontAwesome;
                color: #0080FF;
                font-size: 24px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}
&.bullet_circle {

    ul {
        @media(min-width:$screen-sm-min){
        max-width: 60%;
    }
        margin-top: 20px;

        

        li {
            list-style: none;
            vertical-align: middle;
            font-weight: normal;
            font-size: 16px;
            text-align: left;
            margin-bottom: 20px;
            line-height: 24px;

            &:before {
                position: absolute;
                font-weight: normal;
                content: "\f1db";
                font-family: FontAwesome;
                color: #F9892F;
                font-size: 24px;
                margin-left: -45px;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                line-height: 40px;
                margin-top: -5px;
            }
        }
    }
}
