.image-link-standard {
	&:hover ~ .overlay {
		background-color:$orange;
	}
	h5:hover ~ .overlay {
		background-color:$orange;
	}
	.holder, a {
		display: block;
		position:relative;

	}

	border: 6px solid $light-grey;

	.image-link-inner {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		img {
			width:100%;
			max-height: 100%;
		}


	}
	.link-text {
		position: absolute;
		text-transform: uppercase;
		color:$white;
		bottom:0px;
		left:0px;
		right:0px;
		margin-right:6px;
		margin-left:6px;
		overflow: hidden;
		white-space:nowrap;
		z-index: 89;
	}
	.overlay {
		position: absolute;
		text-transform: uppercase;
		background-color: rgba(0,0,0,0.4);
		height:45px;
		bottom:0px;
		left:0px;
		right:0px;
		z-index:88;
		&:hover {
			background-color:$orange;
		}

	}
	.img-holder:hover ~ .overlay {
		background-color:$orange;
	}
	&:hover .img-holder img {

			-webkit-transform: scale(1.2);
			-moz-transform: scale(1.2);
			-o-transform: scale(1.2);
			transform: scale(1.2);
			transition: all 1s;
			-webkit-transition: all 1s;
		}
	&.White {
		background-color:$white;
		&.image-link-standard {
			border: 6px solid $light-grey;
			h5, p {

				color:$black2;
			}
		}
	}

	&.Blue {
		background-color:$nav-blue;
	}

	&.Orange {
		background-color:$orange;
	}

	&.Black {
		background-color:$black2;
	}
	@media(max-width:$screen-sm-max){
		h5 {
			font-size: $font-xsmall;
			margin-top: 8px;
			margin-bottom: 8px;
		}
		.overlay {
			height: 30%;
		}
	}


}
