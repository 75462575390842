.page {

	.page_row {
		padding-top:15px;
		padding-bottom:15px;
		text-align:center;

		ul {
			text-align: left;
			list-style-type: none;
			display: inline-block;

			li {

				font-weight: normal;

				&:before {
					font-family: FontAwesome;
					content: "\f058";
					font-size: 1.8em;
				    margin-right: 15px;
				    display: inline-block;

				    vertical-align: middle;
				}
			}
		}

		// &:first-child {
		// 	font-size:$font-medium;
		// }

		// &:nth-child(even) {
		// 	background-color:$primary_light;
		// }


	}
}
