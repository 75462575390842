.page_header {
	.mobile {
		h1 {
			font-size:$font-medium;

		}
		.button {
			font-size: $font-xsmall;
			font-weight: $weight-regular;
			&:hover {
				background-color: $secondary;
				border: 2px solid $white;
				color: $white;
			}
		}
	}
	.page_header_inner {
		overflow: hidden;
		margin:0 auto;
		position:relative;
		z-index: 80;

		max-width:1600px;
		height: 250px;

		&.full-width {
			max-width: 100%;
			background-position: center top;
		}
		.map {
			height:340px;

		}

		background-size: cover;
		background-position: center bottom;


		.page_header_text {
			position:absolute;
			top:0px;
			bottom:0;
			left:0;
			right:0;


			background-color: $header-image-overlay;
			&.shrink {
				background-color: transparent;
			}

			transform: translateY(-100%);

			.page_text {
				left: 25%;
				margin: 0 auto;
				margin-top: 70px;


				h1,small {
					margin:5px;

					text-align: center;
					color: $white;
					font-weight: $weight-regular;
					font-size: $font-size-xlarge;
					margin-bottom: 33px;
				}

				small {

					display:block;
					margin-top:30px;
				}
			}
			.button {
				margin-top: 45px;
				transition:color 0.5s, background-color 0.5s;
			}

			@import "resources/assets/sass/_partials/buttons/button";
			@import "resources/assets/sass/_partials/buttons/secondary";

			-moz-animation-name: dropHeader;
			-moz-animation-iteration-count: 1;
			-moz-animation-timing-function: ease-out;
			-moz-animation-duration: 1.0s;

			-webkit-animation-name: dropHeader;
			-webkit-animation-iteration-count: 1;
			-webkit-animation-timing-function: ease-out;
			-webkit-animation-duration: 1.0s;

			animation-delay:1750ms;
			animation-fill-mode: forwards;
			animation-name: dropHeader;
			animation-iteration-count: 1;
			animation-timing-function: ease-out;
			animation-duration: 1.0s;

		}



	}

	// @media(max-width:$screen-sm-max) and (min-width:$screen-sm-min){
	// 	width: 750px;

	// }
	// @media(max-width:$screen-sm-max){
	// 	margin-right: auto;
	// 	margin-left: auto;
	// 	padding-left: 15px;
	// 	padding-right: 15px;
	// }
	@media(max-width:$screen-xs-max){
		.page_header_inner {

			&.shrink {
				max-width:1600px;
				height: 86px;
			}
		}
		.mobile {
			background-color:$light-grey;
			.button {
				border: 2px solid $secondary;
				font-size: $font-xxsmall;
				text-transform: uppercase;
				min-width:140px;
				margin-bottom: 19px;
			}
			h1 {
				font-size:$font-medium;
			}
		}
	}

	.jump_nav {
		.fa {
			color:rgba(255,255,255,0.6);
		}

	}
}

body.index .page_text > h1 {
	font-family: 'Alegreya Sans SC', sans-serif;
	font-size:60px;
}

@-moz-keyframes dropHeader {
	0% {
		-moz-transform: translateY(-100%);
	}
	100% {
		-moz-transform: translateY(0);
	}
}
@-webkit-keyframes dropHeader {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0);
	}
}
@keyframes dropHeader {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

// <img src="/media/img/careers/overview_slider/overview010.jpg"
// srcset="/media/img/careers/overview_slider/header_image@2x.jpg 1600w,
// /media/img/careers/overview_slider/overview010.jpg 870w"

// sizes="(max-width: 640px) 100vw, 870px" alt="">



//ipad normal (port)
@media (max-width:$screen-sm-max) {
	.page_header {
		.page_header_inner {
			.page_header_text {
				.page_text {
					margin-top:15px;

					.button {
						margin-top: 10px;
					}
				}
			}
		}
	}

}

@media (max-width:$screen-sm-max) {
	.page_header {
		.page_header_inner {
			.page_header_text {
				.page_text {
					margin-top:24px;
				}
			}
		}
	}

}



@media(min-width:$screen-xs-min){
	.page_header {

		.page_header_inner {
			max-width:1600px;
			height: 250px;
		}
	}
}


@media (min-width:$screen-sm-min) {
	.page_header {

		.page_header_inner {
			max-width:1600px;
			height: 250px;
			.page_header_text {
				background-color: $header-image-overlay;
			}

		}
	}
}

@media (min-width:$screen-md-min) {
	.page_header {

		.page_header_inner {
			max-width:1600px;
			height: 450px;
			.page_header_text {
				.page_text {

					h1 {
						font-size: $font-size-xxlarge;
						margin-bottom: 5px;
					}

				}
			}
		}

	}

}

//ipad landscape
@media (min-width:$screen-lg-min) {
	.page_header {

		.page_header_inner {
			max-width:1600px;
			height: 450px;
			.page_header_text {
				.page_text {

					h1 {
						font-size: $font-size-max;
					}

				}
			}
		}
	}
}


@media (min-width:1600px) {
	.page_header {
		.page_header_inner {
			max-width:1600px;
			height: 450px;
		}
	}

}
