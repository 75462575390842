.gallery-cards {

	width:100%;
	margin-left:-10px;
	margin-bottom: 15px;
	.image-link {
		box-shadow: 0 2px 3px 0 rgba(183,183,183,0.5);


	}

	&:after {
		clear:both;
	}


}
