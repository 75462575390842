$facebook: #3b5998 !important;
$twitter: #1da1f2 !important;
$google-plus: #dd4b39 !important;
$linkedin: #0077b5 !important;

$light-grey:#F7F7F8;
$dark-grey:#5D6771;
$header-image-overlay: rgba(14,13,36,0.6);
$image-link-card-overlay: rgba(0,0,0,0.4);
$shadow-rectangle: linear-gradient(-180deg, rgba(0,0,0,0.10) 0%, rgba(0,0,0,0.00) 100%);
$post-overlay: rgba(14,13,36,0.8);
$red:#DA564A;


$black: #0E0E25;
$light-blue: #81D5FF;
$nav-blue:#167DB0;
$black2: #0E0D24;
$mobile-menu: rgba(14,14,37,0.9);
$blue: rgba(22,125,176,1);
$orange:rgba(249,137,47,1);
$white:  #FFFFFF;
$off-white: #F7F7F7;
$grey: rgba(61,61,79,1);



// Primary colors
$primary: $light-blue;
$secondary:$orange;


$backgound-primary: $white;
$backgound-secondary: $black2;

$text-color: $black;
$link-color: $nav-blue;
$nav-bar-color:$light-grey;

// Sizes
$font-size-max:50px;
$font-size-xxlarge:36px;
$font-size-xlarge: 30px;
$font-size-large: 24px;
$font-medium: 20px;
$font-small: 18px;
$font19: 19px;
$font-smaller:16px;
$font-xsmall: 14px;
$font-xxsmall:12px;



$font-size-h1: $font-size-xxlarge;
$font-size-h2: $font-size-xlarge;
$font-size-h3: $font-size-large;
$font-size-h4: $font-medium;


$weight-light: 100;
$weight-regular:100;
$weight-bold: 600;

$fa-phone-color: $white;

// Bootstrap mapping

$input-border: $secondary !default;

// Font Awesome

$fa-font-path: "/media/fonts";
$headings-font-family: "Muli", sans-serif;
$font-family-sans-serif:  "Muli", sans-serif;

/* for editor */
$edit-color: green;


// Spacer settings
$spacer-height: 50px;
$spacer-mobile-height:50px;
