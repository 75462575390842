 .button_wrap {
 	margin-top: 30px;
 }
 .page-row-image-left {

 	margin-bottom: 20px;
 	h3 {
 		margin-top: 0;
 	}
 	


 	@import "resources/assets/sass/_partials/pages/bullets";

 	@media(max-width:$screen-sm-max){

 		img {
 			display: inline-block;
 			margin-bottom:20px;
 		}
 		.button_wrap {
 			text-align: center;
 			margin:20px;
 		}
 		p, div {
 			text-align: center;
 		}
 	}
 }

 .pages_rows .page_row .page-row-image-left ul{
 	margin-left: 0;
 	max-width: 100%!important
 }
