.card_items {
	margin-top: 30px;

	display:block;
	position:relative;
	padding: 0;
}


.image-link {
	border: 6px solid transparent;



	&.White {
		background-color:$white;
	}

	&.Blue {
		background-color:$nav-blue;
		&:hover {
			border: 6px solid #8ABED7;
		}
	}



	&.Orange {
		background-color:$orange;
		&:hover {
			border: 6px solid #FCC497;
		}
	}

	&.Black {
		background-color:$black2;
		&:hover {
			border: 6px solid #868591;
		}
	}


	.holder, a {
		display: block;
		position:relative;

	}

	.image-link-inner {
		display: block;
		min-height: 400px;
		.no-margin {
			margin-right: 0;
		}
		.img-holder {

			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 160px;
		}
		.txt-holder {
			margin-top: 15px;
		}


	}
	.link-text {

		text-transform: uppercase;
		color:$white;
		margin-right:20px;
		margin-left:20px;
		min-height: 65px;
		overflow: hidden;

	}

	p {

		color:$white;
		overflow: hidden;
		text-align: center;
		margin-right: 20px;
		margin-left: 20px;
	}
	@media(max-width:$screen-xs-max){
		img {
			width: 80px;
			height: initial!important;
		}
	}
	@media(max-width:$screen-sm-max){
		overflow:hidden;
		// .image-link-container {
		// 	margin-right:-25px;
		// 	margin-left:-25px;
		// }
		.link-text {
			font-size: $font-medium;
			min-height: 0;
			margin-right: 15px;
			margin-left: 15px;
			text-align: left;
		}
		img {
			width: 120px;
			height: 100px;
		}
		p {
			text-align: left;
			font-size: $font-smaller;
			margin-right: 15px;
			margin-left: 15px;
		}
		.image-link-inner {
			min-height: 0;
			.img-holder {
				min-height: 0;
				margin-left:15px;
				height: 117px;
			}
		}
	}
	@media(min-width:$screen-md-min){
		padding-bottom: 10px;
		height: 400px;
		img {
			width:100%;
			max-height: 100%;
		}

		p {
			font-size: $font-medium;
		}
		.img-holder {
			height: 150px;
		}
		.link-text {
			font-size: $font-size-large;
		}
	}
	@media(min-width:$screen-lg-min){
		p {
			font-size: $font-size-large;
		}
		.link-text {
			font-size: $font-size-xlarge;
		}
	}

}

