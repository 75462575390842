.gallery_images {
	.breakout {
		background-color:$light-grey;
		position: relative;
		overflow: hidden;
		text-align:center;
		height: 194px;
		margin-bottom: 1em;
	}
	.overlay {

		display: flex;
		justify-content: center;
		align-items: center;
		position:absolute;
		width:100%;
		height:100%;

	}

	a {
		display: block;

		overflow: hidden;
		position: relative;

		img {
			margin: auto;
		}
	}
	.image-text {
		color: $dark-grey;
		font-size: $font-small;
		text-align:center;
		overflow:hidden;
		white-space: nowrap;
		margin-bottom: 0.5em;
		@media(max-width:$screen-xs-max){
			font-size: $font-xsmall;
		}
	}
}
