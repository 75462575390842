#home_nav {
	top:131px;
	position:fixed;
	width:100%;
	z-index: 90;

}
#other_nav {

	position:relative;
}
#nav {
	z-index: 90;
	width:100%;
	min-height:45px;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.mobile_menu {
		position:relative;
		top:auto;
		bottom:auto;
	}

	ul {
		list-style-type:none;
		margin:0;
		padding:0;
		color:$white;
		letter-spacing:0px;


		& > li {
			display:inline-block;
			text-align:center;

			&.active > a {
				text-decoration: none;
				color: $primary;
				// color: $white;
			}

			ul {
				position:absolute;
				z-index: 100;
				border-top: transparent none 1px;

				&.active {
					display:block;
				}

				li {
					display:block;
					text-align: left;
					img {

					}
					a {
						color:$white;
						padding-bottom:10px;
						padding-top: 11px;
						padding-left: 22px;
						padding-right:46px;

						&:hover, &:active,&:focus {

							color:$primary;
							border-color:transparent;
						}
					}

					&.active a {

						color: $white;
						border-color:transparent;
					}
				}
			}
		}
		a {
			padding:10px;
			display: block;
			color:$white;
			text-transform: lowercase;
			transition:background-color 0.2s ease-out;
			border:transparent solid 1px;
			font-weight: $weight-bold;

			&:hover, &:active,&:focus, &.active {
				text-decoration: none;
				color: $primary;
				// color: $white;

			}
		}
	}




	@media(max-width:$screen-sm-max) {
		& {
			position:fixed;
			width:100%;
			min-height: 0px;
			background-color:transparent;

			align-items: center;
		}

		.mobile_menu {
			transform:translate3d(100%, 0px, 0px);
			transition: transform 0.5s;
			position:fixed;
			top: 51px;
			bottom:inherit;
			height:5000px;
			// margin-right:-15px;
			padding-top: 25px;
			width:108%;
			background-color:$mobile-menu;
			&.banner-active {
				top:133px;

			}

			&.active {
				transform:translate3d(0px, 0px, 0px);

			}

			a {
				text-align: center;
			}

			ul {

				& > li {
					display:block;
					font-size:$font-size-large;

					& > ul.dropdown {
						display:block;
						position: relative;

						margin-left:-15px;
						margin-right:-15px;
						padding-left:15px;
						padding-right:15px;
						border:transparent solid 1px;

						a {
							font-size: 1.1em;
							background-color:transparent;
							color:$white;
						}

						& > li:hover > a, & > li:active > a, & > li.active > a {
							background-color:$nav-blue !important;

						}
					}

					&:hover > a, &:active > a, &.active  > a {
						border:transparent solid 1px;
						color: $white;
						background-color: $nav-blue;
					}
				}
			}

			.contact {
				text-align: center;
				a {
					font-size: $font-small;
					color: $light-blue;
				}

			}
		}

	}
}

body.no_scroll {
	overflow: hidden;
}
