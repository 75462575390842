
.button {
	font-weight: $weight-regular;
	text-decoration: none;
	border: 2px solid $black2;
	font-size: $font-size-large;
	text-transform: lowercase;
	min-width:200px;
	color: $black2;
	padding: 3px 12px;

	&.btn-secondary {
		min-width:220px;

	}

	&:hover {
		background-color: $black2;
		color: $white;
		border: 2px solid $primary;
	}

}
