.staff-outer {
	@media(min-width:$screen-md-min) {
		margin-left:-8.3333333333%;
		margin-right:-8.3333333333%;
	}

}

.staff {
	.staff-inner {


		a {
			text-decoration: none;
			color: $white;
		}

		&:hover .inner-text p small {
			color:$primary;
		}


		.img-holder {
			border: 0.97px solid $white;
			border-radius: 50%;
			overflow: hidden;
			img {
				border-radius: 50%;
			}
		}
		.inner-text {

			h5 {
				font-size: $font-medium;
				color: $white;
				font-weight: $weight-regular
			}
			p {
				font-size: $font-smaller;
				color: $white;
				margin:0;
				font-weight: $weight-light;
				min-height: 50px;
				small {
					font-size: $font-xxsmall;
				}
			}
		}
	}
	@media(max-width:$screen-sm-max) {
		margin-bottom: 30px;
		.staff-inner {
			.no-padding {
				padding-left: 0;
				padding-right: 0;
			}
			.inner-text {

				p {
					min-height: 0;
				}
			}
		}
	}
}
