.post_wrap_grid {
	margin-bottom:30px;
	@media(max-width: $screen-sm-max){
		margin-bottom:20px;
	}
}
.post-grid {
	a {
		text-decoration: none;
		z-index: 102;
	}
	.post-inner {
		color: $white;
		.image-holder {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			&:hover ~  .overlay{
				height: 105px;
			}
			img {
				width: 100%;

			}
		}
		h3 {
			margin-top: 10px;
			color: $white;
			font-size: $font19;
		}
		.overlay {
			padding: 10px;
			position:  absolute;
			bottom:0px;
			left:15px;
			right:15px;
			background-color: $post-overlay;
			z-index: 1;
			overflow: hidden;
			transition: all 0.3s ease-out;
			height: 50px;
			&:hover, &:active {
				height: 130px;
			}
		}
	}

	@media(max-width: $screen-sm-max){
		max-width: 100%;
		.post-inner {
			max-width: 100%;
			.image-holder {
				&:hover ~  .overlay{
					height: 113px;
				}
			}
			.overlay {
				height: 113px;
				&:hover {
					height: 113px;
				}
			}

			img {
				width:100%;
			}

		}

	}
	@media(max-width: $screen-md-max){
		p {
			font-size: $font-xsmall
		}
		.post-inner {
			h3 {
				font-size: 18px;
			}
		}
	}


}


