.job-list-container {
	background-color: $off-white;
	// width:100%;
	margin-bottom: 20px;
	@media(max-width:$screen-sm-max) {
		margin-bottom: 10px;
	}
}
.job-list {

	&:hover >  a .job-list-inner div div .inner-text h5 {
		color: $nav-blue;
	}
		a {
			text-decoration: none;

		}
	.job-list-inner {






		.img-holder {
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 136px;

		}
		.inner-text {
			text-align: left;
			h5 {
				font-size: $font-size-large;
				color: $black2;
				font-weight: $weight-bold;
			}
			.sub-title {
				font-weight: $weight-bold;
				font-size: $font-smaller;
				color: $black2;
			}
			p {
				color: $black2;
				font-size: $font-xsmall;
			}
		}
	}
	@media(min-width:$screen-md-min) {
		img {
			width:70px;
		}
	}


	@media(max-width:$screen-sm-max) {

		.job-list-inner {
			.img-holder {
				img {
					width: 25%;
					padding:20px;
				}
			}
			.inner-text {
				text-align: center;
			}
		}
	}
	@media(max-width:$screen-xs-max) {
		.job-list-inner {
			.img-holder {
				min-height: 0;
				img {
					width: 50%;
				}
			}
			.inner-text {
				h5 {
					margin-top:0;
				}
			}

		}
	}
}
