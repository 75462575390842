.banner-wrap {
	position:fixed;

	width:100%;
	z-index: 90;
}
.banner-menu {
	position:fixed;

	width:100%;
	z-index: 90;
	transition: all .5s ease-in;
	top:-90px;
	&.actives {
		width:100%;
		transform:translateY(0);
		top:0px;
	}

}
.banner-cookie {
	height: 90px;
	top:-90px;
	p {
		font-size: $font-smaller;
	}
}
.banner-shop {
	height:50px;
	top:-50px;
}
.banner {
	a {
		color: $light-blue;
		text-decoration: none;
	}
	#cookie_notice {
		text-transform: uppercase;
	}
	h3 {
		font-size: $font-small;
	}


	background-color: black;
	color:$white;

	span {
		color:$primary;
	}

	@media(max-width:$screen-sm-max) {
		p {
			font-size: $font-xsmall;
		}
		h3 {
			margin-top: 11px;
		}
	}
	@media(max-width:$screen-xs-max) {
		p {
			font-size: $font-xxsmall;
		}
		a#cookie_notice {

			font-size: $font-xsmall;
		}
	}

}
