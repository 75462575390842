.contact-shortcode {
	margin-bottom: 10px;
	margin-top: 10px;
	a {
		text-decoration:  none;
		font-size: $font-small;
		color: $nav-blue;
		&:hover {
			color: $nav-blue;
		}
		&:hover >  .icon-hover {
			display: inline-block;
		}
		&:hover >  .icon-normal {
			display: none;
		}
	}
	img {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 15px;
		margin-top: 15px;
		max-width: 30px;
		&:hover  >  .icon-hover {
			display: inline-block;
		}
		&:hover  >  .icon-normal {
			display: none;
		}
	}
	.icon-hover {
		display: none;
	}


	@media(max-width: $screen-xs-min){
		margin-bottom: 0;
		margin-top: 0;
		img {
			margin: 20px 5px 5px 5px!important;
		}
	}
}
