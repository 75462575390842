
.posts_next {
	margin-bottom: 40px;
	margin-top: 20px;
}
.post {
	.fa-plus-square {
		opacity: 1!important;
	}
	.share {
		margin-top: 15px;
		
		.fa {
			opacity: 0.8;

			&:hover {
				opacity: 1;
			}
		}
	}
	margin-bottom: 20px;
	font-size: $font-small;
	h1 {
		font-size: $font-size-xlarge;
	}
	h4 {
		font-size:  $font-size-large;
		font-weight:  $weight-light;
	}
	.post-flex {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.author {
		font-size: $font-medium;
	}
	.job-title {
		font-weight: $weight-light;
	}
	a {
		text-decoration: none;
		color: $nav-blue;
	}
	ul {
		@media(min-width:$screen-sm-min){
			max-width: 60%;
		}
		margin-top: 20px;
		margin-left:5%;


		li {
			list-style: none;
			vertical-align: middle;
			font-weight: normal;
			font-size: 16px;
			text-align: left;
			margin-bottom: 20px;
			line-height: 24px;

			&:before {
				position: absolute;
				font-weight: normal;
				content: "\f1db";
				font-family: FontAwesome;
				color: #F9892F;
				font-size: 24px;
				margin-left: -45px;
				margin-right: 20px;
				display: inline-block;
				vertical-align: middle;
				line-height: 40px;
				margin-top: -5px;
			}
		}
	}

	@media(max-width: $screen-xs-max){
		margin-bottom: 0;
		font-size: $font-xsmall;

		h1 {
			font-size: $font-medium;
		}
		h4 {
			font-size:  $font-smaller;
		}

	}

	& > img,  p > img {
		max-width:100%;
	}

}
.related {
	margin: 30px 0;
	.h3 {
		font-size: $font-size-xlarge;
		margin-bottom: 30px;
	}
	@media(max-width: $screen-sm-max){
		margin: 0;
		.h3 {

			font-size: $font-medium;
			margin-bottom: 20px;
		}
	}

}
