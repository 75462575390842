.carousel {
	margin-right:0;
	margin-left: 0;
	margin-top: 1em;
}

.carousel-item {

	& > .item {




		img {
			margin-top:5px;
		}

		ul {

		}
	}
}
.carousel-control {

	// background-color:transparent;

	&.right {
		text-align:right;
		padding-right: 24px;
		display: block;
		opacity: 1;
		background-image:none;
		&:hover,&:active,&:focus {
			background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);

		}


	}

	&.left {
		text-align:left;
		display: block;
		padding-left:24px;
		opacity: 1;
		background-image:none;


		&:hover,&:active,&:focus {
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);

		}
	}


	.fa {
		position:relative;
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		font-size:$font-size-xlarge;

		color:$grey;
		text-shadow:none;
		&:hover {
			color:$white;
		}
	}
}
.carousel-text {
	margin-top: 1em;

	ul {
		margin-bottom:6px;
		li {
			list-style: none;
			font-size: $font-small;
			font-weight: $weight-bold;
			text-align: left;
			margin-left:5px;

			&:before {
				position: absolute;
				font-weight: normal;
				content: "\f185";
				font-family: FontAwesome;
				color: $primary;
				font-size: $font-small;
				margin-left: -33px;
				margin-right: 20px;
				display: inline-block;
				vertical-align: middle;
				line-height: 40px;
				margin-top: -5px;
			}
		}
	}
}



@media(max-width:$screen-sm-max){
	.carousel-text {
		ul {
			li {
				font-size: 14px;
			}
		}
	}
}

@media(max-width:$screen-xs-max){
	.carousel-text {

		ul {
			li {
				font-size: $font-xxsmall;
				&:before {
					line-height: 30px;

				}
			}

		}
	}

}
